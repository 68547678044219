import Vue from 'vue';
// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSun, faMoon, faMap, faFilePdf, faStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// Tailwind
import './assets/fonts/karla.css';
import './assets/tailwind.css';
// Components
import App from './App.vue';
import Logo from './components/Logo.vue';
import router from './router'

library.add(faSun);
library.add(faMoon);
library.add(faMap);
library.add(faFacebookSquare);
library.add(faFilePdf);
library.add(faInstagram);
library.add(faStar);

Vue.component('fa', FontAwesomeIcon);
Vue.component('logo', Logo);

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
