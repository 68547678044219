<template>
    <div class="text-center">
        <div class="font-bold mb-4 uppercase text-2xl md:text-3xl">
            Öffnungszeiten
        </div>
        <ul class="text-center text-1xl md:text-2xl">
            <div class="mb-1">Montag: <span class="text-mauros-red">Ruhetag</span></div>
            <div class="mb-1">Dienstag - Donnerstag: <span class="text-mauros-green">17 - 22 Uhr</span></div>
            <div class="mb-1">Freitag: <span class="text-mauros-green">17 - 23 Uhr</span></div>
            <div class="mb-1">Samstag: <span class="text-mauros-green">17 - 23 Uhr</span></div>
            <div class="mb-4">Sonntag: <span class="text-mauros-green">12 - 22 Uhr</span></div>
            <div class="mb-1">Tel: <a class="text-blue-400" href="tel:06233/7787380">06233/7787380</a></div>
        </ul>
    </div>
</template>
<script>
export default {}
</script>
