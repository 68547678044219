<template>
    <a :href="item.url" target="_blank" :class="item.class" class="flex items-center justify-center w-6/12 py-4 text-white font-bold">
        <fa class="mr-2" :icon="item.icon"/> {{ item.name }}
    </a>
</template>
<script>
export default {
    props: {
        item: {},
    },
}
</script>
