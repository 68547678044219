<template>
    <div id="app" class="app pt-16 py-32">
        <div class="w-48">
            <router-link to="/">
                <logo class="bottom-spacer" />
            </router-link>
        </div>


        <router-view/>

        <router-link to="/impressum">
            Impressum
        </router-link>

        <router-link to="/datenschutz">
            Datenschutz
        </router-link>

        <div class="fixed top-3 right-3">
            <ThemeSwitcher />
        </div>

        <div class="fixed bottom-0 left-0 right-0 flex">
            <SocialButton v-for="socialButton in socialButtons" :key="socialButton.name" :item="socialButton" />
        </div>
    </div>
</template>

<script>
import ThemeSwitcher from './components/ThemeSwitcher';
import SocialButton from './components/SocialButton';

export default {
    components: {
        ThemeSwitcher,
        SocialButton,
    },
    data() {
        return {
            socialButtons: [
                {
                    name: 'Facebook',
                    icon: ['fab', 'facebook-square'],
                    class: 'mauros-fb',
                    url: 'https://www.facebook.com/mauros.steak.pasta',
                },
                {
                    name: 'Instagram',
                    icon: ['fab', 'instagram'],
                    class: 'mauros-ig',
                    url: 'https://www.instagram.com/mauros_steak_und_pasta/',
                }
            ]
        }
    },
}
</script>

<style>
html, body {
    @apply min-w-full min-h-full;
}
.mauros-fb {
    @apply bg-facebook;
}
.mauros-ig {
    @apply bg-instagram;
}
.app {
    @apply text-gray-900 dark:text-white;
    @apply flex flex-col flex-nowrap items-center justify-center;
}
.bottom-spacer {
    @apply mb-24 lg:mb-32;
}
h1 {
    @apply text-3xl font-extrabold mb-4;
}
h2 {
    @apply text-2xl mb-2;
}
p {
    @apply mb-4;
}
ul {
    list-style-type: disc;
    @apply pl-5 mb-4;
}
ul ul {
    list-style-type: circle;
}
a {
    @apply text-blue-400;
}
</style>
