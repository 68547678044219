import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Imprint from '../views/Imprint.vue';
import DataPrivacy from '../views/DataPrivacy.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/impressum',
        name: 'Imprint',
        component: Imprint,
    },
    {
        path: '/datenschutz',
        name: 'Data Privacy',
        component: DataPrivacy,
    },
]

const router = new VueRouter({
    routes,
})

export default router;
