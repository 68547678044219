<template>
    <div v-if="!withoutUi">
        <div v-if="isEnded">
            <slot></slot>
        </div>
        <div v-else class="text-4xl uppercase font-bold text-center">
            <div class="text-lg">noch</div>
            <div v-if="days > 0">{{ days }} Tage</div>
            <div v-if="days <= 0 && hours > 0">{{ hours }} Stunden</div>
            <div v-if="days <= 0 && minutes > 0">
                <span v-if="days <= 0 && hours > 0">und</span>
                {{ minutes }} Minuten
            </div>
            <div v-if="days <= 0 && hours <= 0 && seconds > 0">
                <span v-if="days <= 0 && hours <= 0 && minutes > 0">und</span>
                {{ seconds }} Sekunden
            </div>
            <div class="text-lg">bis zur eröffnung!</div>
        </div>
    </div>
    <div v-else-if="isEnded">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        withoutUi: {
            type: Boolean,
            default: false,
        },
        endDate: {
            type: Date // Date.parse(this.endDate)
        }
    },

    data () {
        return {
            days: null,
            hours: null,
            minutes: null,
            seconds: null,
            isEnded: null
        }
    },

    methods: {
        updateRemaining (distance) {
            this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
            this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000) + 1
        },

        tick () {
            const currentTime = new Date()
            const distance = Math.max(this.endDate - currentTime, 0)
            this.updateRemaining(distance)

            if (distance === 0) {
                clearInterval(this.timer)
                this.isEnded = true
            }
        }
    },

    mounted () {
        this.tick()
        this.timer = setInterval(this.tick.bind(this), 1000)
    },

    destroy () {
        clearInterval(this.timer)
    }
}
</script>
