<template>
    <div>
        <div class="flex flex-row flex-wrap items-start justify-center bottom-spacer">
            <a href="https://menu.mauros-restaurant.de/" target="_blank"
               class="flex text-center font-bold bg-gray-100 dark:bg-gray-900 py-2 px-3 mx-2 mb-4">
                <div class="flex items-center">
                    <fa class="mr-4 text-4xl text-gray-400 dark:text-gray-600" :icon="['fas', 'file-pdf']" />
                </div>
                <div>
                    Speisekarte
                    <div class="text-gray-400 dark:text-gray-600">12,2 MB</div>
                </div>
            </a>
            <a href="https://allergene.mauros-restaurant.de/" target="_blank"
               class="flex text-center font-bold bg-gray-100 dark:bg-gray-900 py-2 px-3 mx-2 mb-4">
                <div class="flex items-center">
                    <fa class="mr-4 text-4xl text-gray-400 dark:text-gray-600" :icon="['fas', 'file-pdf']" />
                </div>
                <div>
                    Allergenkarte
                    <div class="text-gray-400 dark:text-gray-600">131 KB</div>
                </div>
            </a>
            <a href="https://www.google.com/maps/place/Mauros+-+Steak+%26+Pasta/@49.5177999,8.2824295,17z/data=!3m1!4b1!4m5!3m4!1s0x4796335c9f11629b:0xf8f0f7f7f2d767c4!8m2!3d49.5177037!4d8.2845988"
               target="_blank"
               class="flex text-center font-bold bg-gray-100 dark:bg-gray-900 py-2 px-3 mx-2 mb-4">
                <div class="flex items-center">
                    <fa class="mr-4 text-4xl text-gray-400 dark:text-gray-600" :icon="['fas', 'map']" />
                </div>
                <div>
                    Anfahrt
                    <div class="text-gray-400 dark:text-gray-600">Google</div>
                </div>
            </a>
            <countdown without-ui :endDate="new Date(2020, 5, 26, 17)">
                <a href="https://feedback.mauros-restaurant.de/"
                   target="_blank"
                   class="flex text-center font-bold bg-gray-100 dark:bg-gray-900 py-2 px-3 mx-2 mb-4">
                    <div class="flex items-center">
                        <fa class="mr-4 text-4xl text-gray-400 dark:text-gray-600" :icon="['fas', 'star']" />
                    </div>
                    <div>
                        Feedback
                        <div class="text-gray-400 dark:text-gray-600">Google</div>
                    </div>
                </a>
            </countdown>
        </div>

        <Countdown class="bottom-spacer"
                   :endDate="new Date(2020, 5, 26, 17)">
            <OpeningHours />
        </Countdown>
    </div>
</template>

<script>
import Countdown from '@/components/Countdown';
import OpeningHours from "@/components/OpeningHours";

export default {
    name: 'Home',
    components: {
        Countdown,
        OpeningHours,
    },
}
</script>
