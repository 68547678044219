<template>
    <div>
        <button v-if="this.darkMode" class="bg-gray-100 dark:bg-gray-900 py-4 px-5 text-2xl" @click.prevent="enableLightMode">
            <fa :icon="['fas', 'sun']" />
        </button>
        <button v-else class="bg-gray-100 dark:bg-gray-900 text-gray-800 py-4 px-5 text-2xl" @click.prevent="enableDarkMode">
            <fa :icon="['fas', 'moon']" />
        </button>
    </div>
</template>
<script>
export default {
    data() {
        return {
            darkMode: false,
        }
    },
    methods: {
        processTheme() {
            if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
                document.documentElement.classList.add('dark')
                this.darkMode = true
            } else {
                document.documentElement.classList.remove('dark')
                this.darkMode = false
            }
        },
        enableDarkMode() {
            localStorage.theme = 'dark'
            this.processTheme();
        },
        enableLightMode() {
            localStorage.theme = 'light'
            this.processTheme();
        },
        enableSystemMode() {
            localStorage.removeItem('theme')
            this.processTheme();
        },
    },
    mounted() {
        this.processTheme();
    },
}
</script>
